import React from 'react';

export const Logo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="131.48" height="50.01" viewBox="0 0 131.48 50.01">
    <polygon
      id="v"
      points="62.64 12 72.9 39 80.84 39 91.1 12 83.43 12 76.85 30.9 70.31 12 62.64 12"
      fill="#2F343C"
      fillRule="evenodd"
    />
    <rect id="y" x="93.86" width="7" height="39" fill="#2F343C" />
    <path
      id="l"
      d="M103.94,12,114.8,37.63a8.15,8.15,0,0,1-2.57,3.92,6.83,6.83,0,0,1-4.37,1.16v6.36a12.65,12.65,0,0,0,8.49-2.31q3.42-2.57,5.47-8.24L131.48,12h-7.31l-5.86,17.79L111.41,12Z"
      fill="#2F343C"
      fillRule="evenodd"
    />
    <path
      id="dot"
      d="M30.59,21a3.49,3.49,0,1,0-3.49-3.49h0A3.51,3.51,0,0,0,30.59,21Z"
      fill="#2D72D2"
      fillRule="evenodd"
    />
    <path
      id="o"
      d="M23.52,14.78a14.16,14.16,0,1,0-2,22.72,6.16,6.16,0,0,1-1.85,4.71,7.21,7.21,0,0,1-5,1.69c-3.29,0-5.6-1.23-6.94-3.71L1.87,43.53a11.52,11.52,0,0,0,5,4.82A16.66,16.66,0,0,0,14.41,50a15.21,15.21,0,0,0,9.77-3.23q4-3.23,4.06-9.37c0-4.18.28-8.48.08-12.64a7.59,7.59,0,0,1-5-9.49l.18-.51ZM6.77,25.42h0a7.42,7.42,0,1,1,7.42,7.42h0A7.43,7.43,0,0,1,6.77,25.42Z"
      fill="#2F343C"
      fillRule="evenodd"
    />
    <path
      id="g"
      d="M37.69,14.81a7.55,7.55,0,0,1,.49,2.7,6.73,6.73,0,0,1-.07,1l3.39,1.75a7.58,7.58,0,1,1-2,5.15v-.74L36,22.85A7.7,7.7,0,0,1,33,24.73v.69a14.15,14.15,0,1,0,4.79-10.61Z"
      fill="#2F343C"
      fillRule="evenodd"
    />
  </svg>
);
